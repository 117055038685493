import type { Price as PriceType } from "~/commerce-sap/.server/api/generated/__generated_apis";
import { useSite } from "~/contexts";

export const Price = ({ price }: { price: PriceType }) => {
  const { locale } = useSite();
  const currentPrice = price?.formattedValue;
  if (!currentPrice && price?.value) {
    return new Intl.NumberFormat(locale.alias, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      currencyDisplay: "symbol",
      style: "currency",
      currency: price.currencyIso,
    }).format(price.value);
  }

  return currentPrice;
};
